<template>
  <Card loading>
    <Heading
      v-skeleton="24"
      level="2" />
    <Paragraph v-skeleton="[52, 51, 45, 38]" />
    <Heading
      v-skeleton="30"
      level="3" />
    <Paragraph v-skeleton="[32]" />
    <Paragraph v-skeleton="[44, 42, 39]" />
    <Paragraph v-skeleton="25" />
  </Card>
</template>

<script>
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';

export default {
  name: 'ReturnsStepsSkeleton',
  components: {
    Card,
    Heading,
    Paragraph,
  },
};
</script>
