<template>
  <Skeleton :loading="!steps.length">
    <template #default>
      <Steps :steps="steps" />
    </template>

    <template #skeleton>
      <ReturnsStepsSkeleton />
    </template>
  </Skeleton>
</template>

<script>
import {
  RETURNS_STEP_LAST,
  RETURNS_STEP_PAYMENT,
  RETURNS_STEP_REASONS,
} from '@/components/returns/returns-steps/steps';
import { mapGetters, mapState } from 'vuex';
import ReturnsStepsSkeleton from '@/components/returns/returns-steps/ReturnsSteps.skeleton';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import Skeleton from '@/components/Skeleton';
import Steps from '@/components/Steps';
import { isDemo } from '@/services/functions/isDemo';

export default {
  name: 'ReturnsSteps',
  components: {
    Skeleton,
    ReturnsStepsSkeleton,
    Steps,
  },

  data() {
    return {
      error: null,

      /**
       * The steps array. More steps get added based on various conditions after
       * returnData is loaded.
       *
       * @see addRelatedShipmentData()
       * @see addAdditionalSteps()
       */
      steps: [],
    };
  },

  computed: {
    ...mapState(STORE_MODULE_RETURNS, ['returnData', 'loadingReturnData', 'loadingReturnReasonSettings']),
    ...mapGetters(STORE_MODULE_RETURNS, ['returnReasonSettings']),

    /**
     * @returns {boolean}
     */
    hasReturnReasons() {
      const hasSelectedReturnReasons = this.returnReasonSettings.return_reasons.length;

      return this.returnReasonSettings && this.returnReasonSettings.enabled && hasSelectedReturnReasons;
    },

    /**
     * Whether the return shipment still needs to be paid for.
     *
     * @returns {boolean}
     */
    requiresPayment() {
      return this.returnData && this.returnData?.payment_instructions;
    },
  },

  watch: {
    '$i18n.locale'() {
      this.$store.dispatch(`${STORE_MODULE_RETURNS}/fetchReturnReasonSettings`);
    },

    loadingReturnData: {
      immediate: true,
      handler() {
        this.onLoad();
      },
    },

    loadingReturnReasonSettings: {
      immediate: true,
      handler() {
        this.onLoad();
      },
    },
  },

  beforeMount() {
    // Start loading the return reasons and comment.
    this.$store.dispatch(`${STORE_MODULE_RETURNS}/fetchReturnReasons`);
    this.$store.dispatch(`${STORE_MODULE_RETURNS}/fetchReturnComment`);
  },

  methods: {
    onLoad() {
      if (this.steps.length > 0 || this.loadingReturnReasonSettings || this.loadingReturnData) {
        return;
      }

      this.addAdditionalSteps();
      this.addRelatedShipmentData();

      // Preload steps
      this.steps.forEach((step) => step.component());
    },

    /**
     * Show a "back" button to the parent shipment if there is one.
     */
    addRelatedShipmentData() {
      const relatedShipmentData = this.returnData.related_shipment_data;

      if (!relatedShipmentData || !relatedShipmentData.barcode) {
        return;
      }

      const backToTrackTraceRoute = isDemo()
        ? { name: 'TrackTraceDemo' }
        : {
          name: 'TrackTrace',
          params: relatedShipmentData,
        };

      this.steps[0].hooks = {
        clickBack: [() => this.$router.push(backToTrackTraceRoute)],
      };

      this.steps[0].buttonAttributes = {
        back: {
          show: true,
          text: this.$t('returns.introduction_back'),
        },
      };
    },

    /**
     * Add the other steps after enough data is present to decide what should and shouldn't be shown.
     */
    addAdditionalSteps() {
      // If payment is required, it's the only available step.
      if (this.requiresPayment) {
        this.steps.push({
          id: RETURNS_STEP_PAYMENT,
          component: () => import('@/components/returns/ReturnsPayment'),
        });
        return;
      }

      if (this.returnReasonSettings && this.hasReturnReasons) {
        this.steps.push({
          id: RETURNS_STEP_REASONS,
          component: () => import('@/components/returns/returns-reasons/ReturnsReasons'),
        });
      }

      this.steps.push({
        id: RETURNS_STEP_LAST,
        component: () => import('@/components/returns/ReturnsLastStep'),
      });
    },
  },
};
</script>
