<template>
  <div class="tw-text-center tw-text-primary-800">
    <BrandingCompanyLogo />
    <Heading v-t="'returns.introduction'" />

    <Skeleton
      :loading="loadingFields"
      centered>
      <template #default>
        <Paragraph
          v-if="fields.returns"
          class="tw-w-full"
          v-html="fields.returns.introduction" />
      </template>

      <template #skeleton>
        <Paragraph v-skeleton="[55, 35]" />
      </template>
    </Skeleton>

    <Skeleton
      :loading="loadingReturnData"
      centered>
      <template #default>
        <Paragraph v-t="expiryDateText" />
      </template>
      <template #skeleton>
        <Paragraph v-skeleton="36" />
      </template>
    </Skeleton>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import Skeleton from '@/components/Skeleton';

export default {
  name: 'ReturnsHeader',
  components: {
    BrandingCompanyLogo: () => import('@/components/branding/BrandingCompanyLogo'),
    Heading,
    Paragraph,
    Skeleton,
  },

  computed: {
    ...mapState(['loadingFields']),
    ...mapState(STORE_MODULE_RETURNS, ['loadingReturnData', 'returnData']),
    ...mapGetters(['fields']),

    /**
     * Use function for filter because filters can only be used in mustache interpolations and v-bind expressions.
     *
     * @returns {string}
     */
    expiryDateText() {
      return {
        path: 'returns.introduction_expiry_date',
        args: {
          date: this.$options.filters.dayjs(this.returnData.expire.date, 'date_format.full'),
        },
      };
    },
  },
};
</script>
