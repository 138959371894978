<template>
  <Page
    class="tw-max-w-screen-sm tw-mx-auto"
    :actions="[
      'returns/fetchReturnData',
      'returns/fetchReturnReasonSettings',
    ]">
    <ReturnsHeader />
    <ReturnsSteps />
  </Page>
</template>

<script>
import Page from '@/views/Page';
import ReturnsHeader from '@/components/returns/returns-header/ReturnsHeader';
import ReturnsSteps from '@/components/returns/returns-steps/ReturnsSteps';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import { mapState } from 'vuex';

export default {
  name: 'Returns',
  components: {
    ReturnsHeader,
    Page,
    ReturnsSteps,
  },

  computed: {
    ...mapState(STORE_MODULE_RETURNS, ['loadingReturnData']),
  },

  watch: {
    '$i18n.locale'() {
      this.$store.dispatch(`${STORE_MODULE_RETURNS}/fetchReturnReasonSettings`);
    },
  },
};
</script>
