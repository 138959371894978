<template>
  <div v-if="steps.length">
    <Transition :name="transition">
      <KeepAlive>
        <component
          :is="steps[currentStep].component"
          :key="`step_child_${currentStep}`"
          v-test="'step'"
          class="steps__content"
          v-bind="{
            ...steps[currentStep].attrs || {},
            ...steps[currentStep].props || {},
          }"
          @addHooks="(args) => addHooks(currentStep, ...args)"
          @navigate="handleNavigation"
          @navigateBack="clickBack"
          @navigateNext="clickNext"
          @setButtonAttributes="(args) => setButtonAttributes(currentStep, ...args)" />
      </KeepAlive>
    </Transition>

    <div
      v-show="showBackButton || showNextButton"
      class="md:tw-pt-7 tw-flex tw-justify-between tw-pt-5">
      <div v-show="showBackButton">
        <Transition name="fade">
          <CButton
            v-test="{ button: 'back' }"
            v-bind="currentButtonAttributes.back"
            @click="clickBack">
            {{ getButtonText(currentStep, 'back') }}
          </CButton>
        </Transition>
      </div>

      <div v-show="showNextButton">
        <Transition name="fade">
          <CButton
            v-test="{ button: 'next', e2e: 'next' }"
            v-bind="currentButtonAttributes.next"
            @click="clickNext">
            {{ getButtonText(currentStep, 'next') }}
          </CButton>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from '@/components/CButton';
import isEqual from 'lodash-es/isEqual';
import merge from 'lodash-es/merge';

const DEFAULT_BUTTON_ATTRIBUTES = {
  next: {
    iconRight: 'arrow-left',
  },
  back: {
    iconLeft: 'arrow-right',
  },
};

const DEFAULT_HOOKS = {
  clickBack: [],
  clickNext: [],
};

export default {
  name: 'Steps',
  components: { CButton },

  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentStep: 0,

      /**
       * The transition of the elements changes depending on whether previous or next is clicked.
       *
       * @type {string|null}
       */
      transition: null,

      /**
       * Additional functions to be executed on hooks like clicking the "back" or "next" button.
       */
      hooks: {},

      buttonAttributes: {},

      loading: false,
    };
  },

  computed: {
    /**
     * @returns {Object}
     */
    currentButtonAttributes() {
      return this.buttonAttributes[this.currentStep];
    },

    /**
     * @returns {boolean}
     */
    forceShowNextButton() {
      return this.currentButtonAttributes.next.show;
    },

    /**
     * @returns {boolean}
     */
    forceShowBackButton() {
      return this.currentButtonAttributes.back.show;
    },

    /**
     * @returns {boolean}
     */
    showBackButton() {
      return Boolean(this.currentStep > 0 || this.forceShowBackButton);
    },

    /**
     * @returns {boolean}
     */
    showNextButton() {
      return Boolean(this.currentStep < this.steps.length - 1 || this.forceShowNextButton);
    },
  },

  watch: {
    steps: {
      /**
       * Set values for button attributes and hooks for each step, if any values are different. This is to allow new
       *  steps to be added later.
       *
       * @param {Object[]} steps
       */
      handler(steps) {
        steps.forEach((step, index) => {
          const attributes = merge({}, DEFAULT_BUTTON_ATTRIBUTES, step.buttonAttributes || null);
          const hooks = merge({}, DEFAULT_HOOKS, step.hooks || null);

          if (!isEqual(attributes, this.buttonAttributes[index])) {
            this.setButtonAttributes(index, attributes);
          }

          if (!isEqual(hooks, this.hooks[index])) {
            this.addHooks(index, hooks);
          }
        });
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    /**
     * Add hooks to a step.
     *
     * @param {number} step
     * @param {Object<string, Function[]>} hooks
     */
    addHooks(step, hooks) {
      this.hooks[step] = {
        ...this.hooks[step],
        ...hooks,
      };
    },

    /**
     * Run any hooks and trigger navigation.
     */
    async clickBack() {
      await this.doHooks('back');

      if (this.forceShowBackButton) {
        return;
      }

      this.handleNavigation(this.currentStep - 1);
    },

    /**
     * Run any hooks and trigger navigation.
     */
    async clickNext() {
      await this.doHooks('next');

      if (this.forceShowNextButton) {
        return;
      }

      this.handleNavigation(this.currentStep + 1);
    },

    /**
     * Set attributes on the back and/or next buttons. It's done in a verbose way because Vue 2 will not detect changes
     *  if we don't explicitly reassign the whole variables.
     *
     * @param {number} step
     * @param {Object} attributes
     */
    setButtonAttributes(step, attributes) {
      this.buttonAttributes = {
        ...this.buttonAttributes,
        [step]: merge({}, this.buttonAttributes[step], attributes),
      };
    },

    /**
     * Navigate to given step by id or index. Skips transition if triggered with an id.
     *
     * @param {number|string} newStep
     */
    handleNavigation(newStep) {
      let transition = true;

      if (typeof newStep === 'string') {
        transition = false;
        newStep = this.findStepById(newStep);
      }

      if (newStep > this.steps.length - 1 || newStep < 0) {
        return;
      }

      if (transition) {
        this.transition = newStep > this.currentStep ? 'slide-left' : 'slide-right';
      } else {
        this.transition = null;
      }

      this.currentStep = newStep;
    },

    /**
     * Get the index of a step by id.
     *
     * @param {string} stepId
     * @returns {number}
     */
    findStepById(stepId) {
      const stepIndex = this.steps.findIndex((step) => step.id === stepId);

      if (stepIndex === -1) {
        throw new Error(`Step ${stepId} does not exist`);
      }

      return stepIndex;
    },

    /**
     * @param {number} step
     * @param {'back' | 'next'} type
     *
     * @returns {string}
     */
    getButtonText(step, type) {
      const attributes = this.buttonAttributes[step][type];

      if (attributes && attributes.text) {
        return attributes.text;
      }

      return this.$t(type);
    },

    /**
     * Run hooks for given navigation type.
     *
     * @param {string} type - "next" or "back".
     *
     * @returns {Promise}
     */
    async doHooks(type) {
      let hooks;

      switch (type) {
        case 'next':
          hooks = this.hooks[this.currentStep].clickNext;
          break;
        case 'back':
          hooks = this.hooks[this.currentStep].clickBack;
          break;
        default:
          return;
      }

      if (!hooks.length) {
        return;
      }

      this.loading = true;
      this.setButtonAttributes(this.currentStep, {
        [type]: {
          ...this.currentButtonAttributes[type],
          loading: true,
        },
      });

      await Promise.all(hooks.map((hook) => hook(this)));

      this.setButtonAttributes(this.currentStep, {
        [type]: {
          ...this.currentButtonAttributes[type],
          loading: false,
        },
      });

      this.loading = false;
    },
  },
};
</script>
